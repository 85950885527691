﻿export default {
    props: {
        displayed: {
            type: Boolean
        }
    },
    template: `
        <div v-bind:class="{'modal': true, 'auth-required': true, 'show-modal': isDisplayed }">
            <div class="modal__content" v-click-outside="hideWindow">
                <img src="/img/popup/close.svg" v-on:click="isDisplayed = false;" alt="close" class="modal__closeBtn modal__closeBtn-questions" />
                <slot></slot>
                <img src="/img/popup/dog.png" alt="dog" class="modal__contentImg" />
            </div>
        </div>`,
    data: function () {
        return {
            isDisplayed: this.displayed
        };
    },
    watch: {
        displayed(newValue) {
            this.isDisplayed = newValue;
        }
    },
    methods: {
        hideWindow: function () {
            this.isDisplayed = false;
        }
    }
}