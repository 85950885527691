﻿import app from '../../js/site.js';
import axios from 'axios'
import Vue from 'vue'

window.landing = new Vue({
    extends: app,
    data: {
        map: null,
        marker: null,
        subscribeStatus: 0,
        feedback: {
            email: '',
            name: '',
            description: '',
            status: 0
        },
        subscribeEmail: '',
        services: [],
        articles: {
            list: [],
            currPage: 1,
            totalPages: -1
        }
    },
    mounted: function () {
        window.mapApiInitialized = this.initMap;
        let obj = this;
        let script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCRxSK63hogwAp9gVbnb2OKX3RLynnMZ64&callback=mapApiInitialized';
        document.body.appendChild(script);

        this.loadArticles(this.articles.currPage, 3)
            .then(() => {
                obj.http.get('/services/load')
                    .then(result => {
                        obj.services = result.data;
                    })
                    .then(() => {
                        var serviceHeader = $('.main__indexServicesTabButtons');
                        serviceHeader.slick({
                            slidesToShow: 6,
                            slidesToScroll: 1,
                            asNavFor: '.main__indexServicesTabs',
                            dots: false,
                            centerMode: false,
                            adaptiveHeight: true,
                            autoplay: true,
                            autoplaySpeed: 4000,
                            pauseOnHover: true,
                            pauseOnFocus: true,
                            focusOnSelect: true,
                            prevArrow: $('.main__indexServicesArrow-prev'),
                            nextArrow: $('.main__indexServicesArrow-next')
                        });

                        $('.main__indexServicesTabButtons').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                            $(slick.$slides[nextSlide]).addClass('main__indexServicesTabButton-active');
                            $(slick.$slides[currentSlide]).removeClass('main__indexServicesTabButton-active');
                        });

                        var slides2 = document.querySelectorAll('.main__indexServicesTabButton');
                        slides2[0].classList.add('main__indexServicesTabButton-active');

                        var serviceTab = $('.main__indexServicesTabs');
                        serviceTab.slick({
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            asNavFor: '.main__indexServicesTabButtons',
                            arrows: false,
                            dots: false,
                            centerMode: false,
                            adaptiveHeight: true
                        });

                        $(document).on('mouseover', '.slick-active', function () {
                            serviceHeader.slick('pause');
                            serviceTab.slick('pause');
                        });

                        $(document).on('mouseout', '.slick-active', function () {
                            serviceHeader.slick('play');
                            serviceTab.slick('play');
                        });
                    });
            });
    },
    methods: {
        loadArticles: function (pageNumber = 1, pageSize = 3) {
            return this.http.get(`/api/articles/load?pageNumber=${pageNumber}&pageSize=${pageSize}`)
                .then(result => {
                    this.articles.list = this.articles.list.concat(result.data.items)
                    this.articles.currPage = result.data.currentPage;
                    this.articles.totalPages = result.data.totalPages;
                });
        },
        initMap: function () {
            var myLatLng = { lat: 47.107635, lng: 37.642391 };
            this.map = new google.maps.Map(document.getElementById("map"), {
                center: myLatLng,
                zoom: 14,
            });

            this.marker = new google.maps.Marker({
                position: myLatLng,
                title: "Орджонікідзевська ветеринарна клініка",
                map: this.map,
                icon: "img/contacts/point.png",
            });
        },
        submitFeedback: function (e) {
            e.preventDefault();
            if (!jQuery(this.$refs.feedbackForm).valid()) return;

            var formData = new FormData();
            var obj = this;
            formData.append('__RequestVerificationToken', this.requestVerificationToken);
            formData.append('Email', this.feedback.email);
            formData.append('Name', this.feedback.name);
            formData.append('Question', this.feedback.description);
            this.feedback.status = 1;
            grecaptcha.execute(CAPTCHA_SITE, { action: 'homepage' }).then(function (token) {
                formData.append('CaptchaToken', token);
                obj.http.post('/feedback/send', formData)
                    .then((response) => {
                        obj.feedback.status = 0;
                        obj.$notify({
                            group: 'foo',
                            duration: 7000,
                            title: 'Вопрос',
                            text: response.data
                        });
                    })
                    .catch(err => {
                        obj.feedback.status = 0;
                        obj.$notify({
                            group: 'error',
                            duration: 5000,
                            title: 'Ошибка',
                            text: err.response.data
                        });
                    });
            })
            .catch(err => {
                obj.feedback.status = 0;
                obj.$notify({
                    group: 'error',
                    duration: 5000,
                    title: 'Ошибка',
                    text: 'Что то пошло не так. Попробуйте позже.'
                });
            });
        },
        renderSliderHeader: function (header) {
            var splittedList = header.split(' ');
            var blackLastIndex = splittedList.length / 2;
            var blackHeader = splittedList.filter((item, index, arr) => index < blackLastIndex).join(' ');
            var yellowHeader = splittedList.filter((item, index, arr) => index >= blackLastIndex).join(' ');

            return `${blackHeader} <span>${yellowHeader}</span>`;
        },
        loadMoreArticles: function () {
            this.loadArticles(this.articles.currPage + 1, 3);
        },
        subscribeToNews: function () {
            if (!jQuery(this.$refs.subscribeForm).valid()) return;

            var obj = this;
            var formData = new FormData();
            formData.append('__RequestVerificationToken', this.requestVerificationToken);
            formData.append('Email', this.subscribeEmail);
            this.subscribeStatus = 1;
            grecaptcha.execute(CAPTCHA_SITE, { action: 'homepage' }).then(function (token) {
                formData.append('CaptchaToken', token);
                obj.http.post('/Subscription/Subscribe', formData)
                    .then(result => {
                        obj.subscribeStatus = 0;
                        if (result.data.successfull) {
                            obj.$notify({
                                group: 'foo',
                                duration: 7000,
                                title: 'Вы были подписаны',
                                text: result.data.message
                            });
                        }
                        else {
                            obj.$notify({
                                group: 'foo',
                                type: 'warn',
                                title: 'Ошибка',
                                text: result.data.message
                            });
                        }
                    })
                    .catch(error => {
                        obj.subscribeStatus = 0;
                        obj.$notify({
                            group: 'error',
                            duration: 5000,
                            title: 'Ошибка',
                            text: error.response.data
                        });
                    });
            });
        }
    }
});