﻿import Vue from 'vue'
import axios from 'axios'
import Notifications from 'vue-notification'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import '../scss/vue-popup.scss'
import '../scss/checkbox.scss'
import vClickOutside from 'v-click-outside'
import Popup from '../js/components/popup.js'

const DefaultNotificationDisplayDuration = 150000;

window.getParam = function (param, deflt) {
    if (typeof (deflt) == 'undefined') deflt = null;
    if (!window.params || !window.params[param]) return deflt;
    return window.params[param];
}

Vue.use(Notifications);
Vue.use(Viewer);
Vue.use(vClickOutside);
Vue.component('popup', Popup);

export default {
    name: 'main-block1',
    el: '#main-block',
    data: {
        isLoaded: false,
        notifications: [],
        pendingFaqCount: getParam('pendingFaqCount', 2),
        baseDomain: BASE_DOMAIN,
        modals: {
            question: {
                displayed: false,
                status: 0,
                name: '',
                email: '',
                description: ''
            }
        }
    },
    mounted() {
        document.body.classList.remove('hidden');
        window.addEventListener('load', () => {
            this.isLoaded = true;
        })
    },
    watch: {
        notificationCopy: {
            handler: function (newArray, oldArray) {
                if (newArray.length > oldArray.length) {
                    var index = newArray.length - 1;
                    if (index >= 0) {
                        setTimeout(() => {
                            this.notifications.splice(this.notifications.length - 1, 1);
                        }, DefaultNotificationDisplayDuration);
                    }
                }
            },
            deep: true
        }
    },
    computed: {
        notificationCopy: function () {
            return this.notifications.slice();
        },
        http: function () {
            if (!this._http) this._http = axios.create({ baseURL: BASE_DOMAIN });
            Object.assign(this._http.defaults, { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
            return this._http;
        },
        requestVerificationToken: function () {
            return document.querySelector('[name=__RequestVerificationToken]')?.value;
        }
    },
    methods: {
        askQuestion: function (e) {
            e.preventDefault();
            if (!jQuery(this.$refs.questionForm).valid()) return;

            var formData = new FormData();
            var obj = this;
            this.modals.question.status = 1;
            formData.append('__RequestVerificationToken', this.requestVerificationToken);
            formData.append('Email', this.modals.question.email);
            formData.append('Name', this.modals.question.name);
            formData.append('Question', this.modals.question.description);

            grecaptcha.execute(CAPTCHA_SITE, { action: 'homepage' }).then(function (token) {
                formData.append('CaptchaToken', token);
                obj.http.post('/feedback/send', formData)
                    .then((response) => {
                        obj.modals.question.status = 0;
                        obj.modals.question.displayed = false;
                        obj.$notify({
                            group: 'foo',
                            duration: 7000,
                            title: 'Вопрос',
                            text: response.data
                        });
                    })
                    .catch(err => {
                        obj.modals.question.status = 0;
                        obj.modals.question.displayed = false;
                        obj.$notify({
                            group: 'error',
                            duration: 5000,
                            title: 'Ошибка',
                            text: err.response.data
                        });
                    });
            })
            .catch(err => {
                obj.modals.question.status = 0;
                obj.modals.question.displayed = false;
                obj.$notify({
                    group: 'error',
                    duration: 5000,
                    title: 'Ошибка',
                    text: 'Что то пошло не так. Попробуйте позже.'
                });
            });
        }
    }
}

if (!Array.prototype.findIndex) {
    Array.prototype.findIndex = function (predicate) {
        if (this == null) {
            throw new TypeError('Array.prototype.findIndex called on null or undefined');
        }
        if (typeof predicate !== 'function') {
            throw new TypeError('predicate must be a function');
        }
        var list = Object(this);
        var length = list.length >>> 0;
        var thisArg = arguments[1];
        var value;

        for (var i = 0; i < length; i++) {
            value = list[i];
            if (predicate.call(thisArg, value, i, list)) {
                return i;
            }
        }
        return -1;
    };
}